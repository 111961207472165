import React from "react";
import ReactDOM from "react-dom";

import { Link, Route, Switch, BrowserRouter } from "react-router-dom";

import AdminLogin, { LoginAuth, api_link, images_root } from "./AdminLogin";
import {
  Products,
  AddProduct,
  EditProduct,
  ManageCatalogues,
} from "./Products/";
import ProductSkuTags from "./ProductSkuTags";
import { Categories, BrandStores, HomeAndKitchen } from "./Categories/";
import { ActiveCustomers, BlockCustomers } from "./Customers/";
import {
  DynamicPages,
  SliderSetup,
  SocialSetup,
  FAQs,
  StoreSetup,
} from "./EditSite/";
import { Email, PushNotifications, SMS } from "./Marketing";
import { PendingQueries, RespondedQueries } from "./Queries";
import axios from "axios";
import $ from "jquery";
import CKEditor from "react-ckeditor-component";

class Header extends React.Component {
  logout(e) {
    e.preventDefault();
    LoginAuth.isAuthenticated = false;
    window.sessionStorage.removeItem(window.btoa("session_admin"));
    window.sessionStorage.removeItem(window.btoa("id"));
    document.title = "Admin | Login";
    window.history.pushState(null, null, "/");
    ReactDOM.render(<AdminLogin />, document.getElementById("root"));
  }

  componentWillUnmount() {
    $("body").attr("style", "background-color:#e9ecef");
  }

  render() {
    $("head").append(`<div id="loader-wrapper" style="display:none">
            <div id="loader"> <img src="dist/img/loading.gif" style="width:100px"></div>
        </div>`);
    $("body")
      .attr("class", "hold-transition skin-yellow-light fixed sidebar-mini")
      .attr("style", "color:#000");
    return (
      <header className="main-header">
        {/* Logo */}
        <Link to="/dashboard" className="logo">
          {/* mini logo for sidebar mini 50x50 pixels */}
          <img
            src={images_root + "logo.png"}
            alt="Logo"
            style={{ height: "80%", objectFit: "contain" }}
          />
        </Link>
        {/* Header Navbar: style can be found in header.less */}
        <nav className="navbar navbar-static-top">
          {/* Sidebar toggle button*/}
          <div className="sidebar-toggle" data-toggle="push-menu" role="button">
            <span className="sr-only">Toggle navigation</span>
          </div>
          {/* Navbar Right Menu */}
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              {/* User Account: style can be found in dropdown.less */}
              <li className="dropdown user user-menu">
                <Link
                  to="/"
                  onClick={this.logout.bind(this)}
                  name="logout"
                  className="btn btn-success btn-flat"
                >
                  Sign out
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

class Navbar extends React.Component {
  state = {
    navbar: [],
    data: null,
  };

  constructor() {
    super();
    const url = api_link + "NavbarFetch.php";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        this.setState({ navbar: data });
      });
  }

  adminNav = (e) => {
    if (e.currentTarget.className === "") {
      $(".sidebar-menu > li").attr("class", "");
      $(".sidebar-menu > li > ul > li").attr("class", "");
      e.currentTarget.className = "active";
    } else {
      if (!e.currentTarget.querySelector("ul")) {
        $(".sidebar-menu > li").attr("class", "");
        e.currentTarget.className = "";
      }
    }

    if (!e.currentTarget.innerHTML.includes("treeview-menu")) {
      if (window.innerWidth <= 800) {
        $(".sidebar-toggle").click();
      }
    }
  };

  adminNavChild = (e) => {
    if (e.currentTarget.className === "") {
      $(".sidebar-menu > li > ul > li").attr("class", "");
      e.currentTarget.parentElement.parentElement.className = "active";
      e.currentTarget.className = "active";
    } else {
      $(".sidebar-menu > li > ul > li").attr("class", "");
      e.currentTarget.parentElement.parentElement.className = "";
      e.currentTarget.className = "";
    }

    if (window.innerWidth <= 800) {
      $(".sidebar-toggle").click();
    }
  };

  render() {
    function detectMob() {
      return window.innerWidth <= 800 && window.innerHeight <= 600;
    }

    function closeNav() {
      if (detectMob()) {
        $(".sidebar-toggle").click();
      }
    }

    return (
      <section className="sidebar">
        {/* Sidebar user panel */}
        <div className="user-panel">
          <div className="image">
            <form
              encType="multipart/form-data"
              action="image_upload_demo_submit.php"
              method="post"
              name="image_upload_form"
              id="image_upload_form"
            >
              <div id="imgArea" className="pull-left image">
                <img
                  src={images_root + "logo.png"}
                  alt="Logo"
                  width={48}
                  height={48}
                />
                <div className="progressBar">
                  <div className="bar" />
                  <div className="percent">0%</div>
                </div>
              </div>
            </form>
          </div>
          <div className="pull-left info">
            <p>Admin</p>
          </div>
        </div>
        {/* /.search form */}
        {/* sidebar menu: : style can be found in sidebar.less */}
        {/*<ul className="sidebar-menu" data-widget="tree" dangerouslySetInnerHTML={{__html: this.state.navbar }}></ul>*/}
        <ul className="sidebar-menu" data-widget="tree">
          {this.state.navbar.map((d, idx) => (
            <li onClick={this.adminNav.bind(this)} key={idx}>
              {d.link === "#" ? (
                <React.Fragment>
                  <Link to="#" className="nav-link">
                    <i className={d.icon}></i>
                    &nbsp;{d.head}
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </i>
                    </span>
                  </Link>
                  <ul className="treeview-menu" style={{ paddingLeft: "20px" }}>
                    {d.child.map((c, cid) => (
                      <li
                        onClick={this.adminNavChild.bind(this)}
                        className={c.class}
                        key={cid}
                      >
                        <Link to={"/" + c.link} className="nav-link">
                          <i className={"fa " + c.icon}></i>
                          {c.head}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              ) : (
                <Link
                  to={"/" + d.link}
                  className="nav-link"
                  onClick={() => closeNav()}
                >
                  <i className={d.icon}></i>
                  &nbsp; {d.head}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </section>
    );
  }
}

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      customers: 0,
      active_customers: 0,
      blocked_customers: 0,
      new_products: 0,
      active_products: 0,
      blocked_products: 0,
      notifications: 0,
      sms_sent: 0,
    };
  }

  componentDidMount() {
    document.title = "Dashboard | Admin";
    let formData = new FormData();
    formData.append("pending", "pending");

    this.childKey = 0;

    const url = api_link + "FetchCounters.php";
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          new_products: data["new_products"],
          active_products: data["active_products"],
          blocked_products: data["blocked_products"],
          customers: data["customers"],
          active_customers: data["active_customers"],
          blocked_customers: data["blocked_customers"],
          sms_sent: data["sms_sent"],
          notifications: data["notifications"],
        });
      });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1> Dashboard</h1>
        </section>
        {/* Main content */}
        <section className="content">
          <div className="row">
            <div className="col-md-12" style={{ clear: "both" }}>
              <h3>Catalogue</h3>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="ion ion-ios-cart-outline" />
                </span>
                <div className="info-box-content one">
                  <span className="info-box-text">
                    <i className="ion ion-ios-cart-outline m_icon" /> No Of
                    Products
                  </span>
                  <span className="info-box-number">
                    {this.state.new_products}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="ion ion-ios-gear-outline" />
                </span>
                <div className="info-box-content one">
                  <span className="info-box-text">
                    <i className="ion ion-ios-gear-outline m_icon" /> No Of
                    Active Products
                  </span>
                  <span className="info-box-number">
                    {this.state.active_products}
                  </span>
                </div>
                {/* /.info-box-content */}
              </div>
              {/* /.info-box */}
            </div>

            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="info-box">
                <span className="info-box-icon bg-yellow">
                  <i className="ion ion-ios-people-outline" />
                </span>
                <div className="info-box-content two">
                  <span className="info-box-text">
                    <i className="ion ion-ios-people-outline m_icon" /> No Of
                    Blocked Products
                  </span>
                  <span className="info-box-number">
                    {this.state.blocked_products}
                  </span>
                </div>
                {/* /.info-box-content */}
              </div>
              {/* /.info-box */}
            </div>

            <div className="col-md-12" style={{ clear: "both" }}>
              <h3>Franchises</h3>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="fa  fa-handshake" />
                </span>
                <div className="info-box-content one">
                  <span className="info-box-text">
                    <i className="fa fa-handshake m_icon" /> Total Customers
                  </span>
                  <span className="info-box-number">
                    {this.state.customers}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="info-box">
                <span className="info-box-icon bg-yellow">
                  <i className="fa fa-credit-card" />
                </span>
                <div className="info-box-content two">
                  <span className="info-box-text">
                    <i className="fa fa-credit-card m_icon" /> Active Customers
                  </span>
                  <span className="info-box-number">
                    {this.state.active_customers}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="fa fa-percent" />
                </span>
                <div className="info-box-content one">
                  <span className="info-box-text">
                    <i className="fa fa-percent m_icon" /> Blocked Customers
                  </span>
                  <span className="info-box-number">
                    {this.state.blocked_customers}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-12" style={{ clear: "both" }}>
              <h3>Marketing</h3>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="info-box">
                <span className="info-box-icon bg-yellow">
                  <i className="fa fa-credit-card" />
                </span>
                <div className="info-box-content two">
                  <span className="info-box-text">
                    <i className="fa fa-credit-card m_icon" /> No Of
                    Notifications Read
                  </span>
                  <span className="info-box-number">
                    {this.state.notifications}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="fa fa-percent" />
                </span>
                <div className="info-box-content one">
                  <span className="info-box-text">
                    <i className="fa fa-percent m_icon" /> No Of SMS Sent
                  </span>
                  <span className="info-box-number">{this.state.sms_sent}</span>
                </div>
              </div>
            </div>
          </div>

          {/* /.row */}
        </section>
        <div className="control-sidebar-bg" />
      </div>
    );
  }
}

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      contact: "",
      alt_contact: "",
      address: "",
      other_address: "",
      pin: "",
      tech_contact: "",
      tech_email: "",
      gstin: "",
      logo: "",
      elogo: "",
      icon: "",
      eicon: "",
      current_pass: "",
      new_pass: "",
      confirm_pass: "",
    };

    let formData = new FormData();
    formData.append("profile", "profile");

    this.childKey = 0;

    axios({
      method: "post",
      url: api_link + "SetupFetch.php",
      data: formData,
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          name: data[0]["name"],
          email: data[0]["email"],
          contact: data[0]["contact"],
          alt_contact: data[0]["alternate_contact"],
          address: data[0]["address"],
          other_address: data[0]["other_address"],
          pin: data[0]["pin"],
          tech_contact: data[0]["technical_contact"],
          tech_email: data[0]["technical_email"],
          gstin: data[0]["gstin"],
          logo: data[0]["logo"],
          icon: data[0]["icon"],
        });
      });
  }

  componentDidMount() {
    document.title = "Profile Setup";
  }

  handleBasicFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    let formData = new FormData();
    formData.append("profile", "update");
    formData.append("name", this.state.name);
    formData.append("contact", this.state.contact);
    formData.append("alt_contact", this.state.alt_contact);
    formData.append("technical_contact", this.state.tech_contact);
    formData.append("email", this.state.email);
    formData.append("technical_email", this.state.tech_email);
    formData.append("address", this.state.address);
    formData.append("other_address", this.state.other_address);
    formData.append("pin", this.state.pin);
    formData.append("gstin", this.state.gstin);

    axios({
      method: "post",
      url: api_link + "ProfileManagement.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data === "ok") {
          $("#update_message").html(
            "<strong>Success! </strong> Your Request Successfully Processed"
          );
          $("#update_message")
            .removeClass("alert-danger")
            .addClass("alert-success");
        } else {
          $("#update_message").html(
            "<strong>Error! </strong> Unable to Process Your Request"
          );
          $("#update_message")
            .removeClass("alert-success")
            .addClass("alert-danger");
        }
        $("#update_message").show();
        setTimeout(function () {
          $("#update_message").hide();
        }, 4000);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  handleLogoFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();
    let formData1 = new FormData();
    formData1.append("update_logo", "update_logo");
    formData1.append("logo", this.state.elogo);
    axios({
      method: "post",
      url: api_link + "ProfileManagement.php",
      data: formData1,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then(function (response) {
      //handle success
      if (response.data === "ok") {
        $("#update_message").html(
          "<strong>Success! </strong> Your Request Successfully Processed"
        );
        $("#update_message")
          .removeClass("alert-danger")
          .addClass("alert-success");
      } else {
        $("#update_message").html(
          "<strong>Error! </strong> Unable to Process Your Request"
        );
        $("#update_message")
          .removeClass("alert-success")
          .addClass("alert-danger");
      }
      $("#update_message").show();
      setTimeout(function () {
        $("#update_message").hide();
      }, 4000);
    });
  }

  handleIconFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();
    let reader = new FileReader();
    reader.readAsDataURL(this.state.eicon);
    reader.onload = (e) => {
      let formData = new FormData();
      formData.append("update_icon", "update_icon");
      formData.append("icon", this.state.eicon);

      axios({
        method: "post",
        url: api_link + "ProfileManagement.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            $("#update_message").html(
              "<strong>Success! </strong> Your Request Successfully Processed"
            );
            $("#update_message")
              .removeClass("alert-danger")
              .addClass("alert-success");
          } else {
            $("#update_message").html(
              "<strong>Error! </strong> Unable to Process Your Request"
            );
            $("#update_message")
              .removeClass("alert-success")
              .addClass("alert-danger");
          }
          $("#update_message").show();
          setTimeout(function () {
            $("#update_message").hide();
          }, 4000);
        })
        .catch(function (response) {
          //handle error
          $(".loader_gif").fadeOut("slow");
          console.log(response);
        });
    };
  }

  handlePasswordFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    if (this.state.new_pass === this.state.confirm_pass) {
      let formData = new FormData();
      formData.append("current_pass", this.state.current_pass);
      formData.append("new_pass", this.state.new_pass);
      formData.append("confirm_pass", this.state.confirm_pass);
      formData.append("update_password", "update_password");
      formData.append(
        "email",
        window.atob(window.sessionStorage.getItem(window.btoa("session_admin")))
      );

      axios({
        method: "post",
        url: api_link + "ProfileManagement.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          if (response.data === "ok") {
            $("#update_message").html(
              "<strong>Success! </strong> Your Request Successfully Processed"
            );
            $("#update_message")
              .removeClass("alert-danger")
              .addClass("alert-success");
          } else {
            $("#update_message").html(
              "<strong>Error! </strong> Unable to Process Your Request"
            );
            $("#update_message")
              .removeClass("alert-success")
              .addClass("alert-danger");
          }
          window.scrollTo({ top: 0, behavior: "smooth" });
          $("#update_message").show();
          setTimeout(function () {
            $("#update_message").hide();
          }, 4000);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      $("#update_message").html(
        "<strong>Error! </strong> Please Enter Same Password!"
      );
      $("#update_message")
        .removeClass("alert-success")
        .addClass("alert-danger");
      $("#update_message").show();
      setTimeout(function () {
        $("#update_message").hide();
      }, 4000);
    }
  }

  render() {
    return (
      <div className="content-wrapper">
        <section>
          <div
            id="update_message"
            className="alert"
            style={{ display: "none" }}
          ></div>
        </section>
        <section className="content-header">
          <h1> Profile Setup</h1>
        </section>
        <section className="content">
          <div className="box">
            <div className="box-body">
              {/*CHANGE BASIC DETAILS*/}
              <form
                method="post"
                onSubmit={this.handleBasicFormSubmit.bind(this)}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Shop Title*</label>
                    <input
                      type="text"
                      id="name"
                      required
                      name="name"
                      pattern="[a-zA-Z ]{3,50}"
                      defaultValue={this.state.name}
                      className="form-control"
                      placeholder="Name"
                      title="Enter characters only"
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Email*</label>
                    <input
                      type="email"
                      id="email"
                      required
                      name="email"
                      defaultValue={this.state.email}
                      className="form-control"
                      placeholder="Email"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Contact No*</label>
                    <input
                      type="text"
                      id="contact"
                      required
                      name="contact"
                      pattern="[0-9]{10,15}"
                      defaultValue={this.state.contact}
                      className="form-control"
                      placeholder="Contact No"
                      title="Enter valid number"
                      max={15}
                      onChange={(e) =>
                        this.setState({ contact: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Alternate Contact No</label>
                    <input
                      type="text"
                      id="contact"
                      name="alternate_contact"
                      pattern="[0-9]{10,15}"
                      defaultValue={this.state.alt_contact}
                      className="form-control"
                      placeholder="Contact No"
                      title="Enter valid number"
                      max={15}
                      onChange={(e) =>
                        this.setState({ alt_contact: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Technical Contact Number</label>
                    <input
                      type="text"
                      required
                      name="technical_contact"
                      defaultValue={this.state.tech_contact}
                      className="form-control"
                      placeholder="Technical Contact"
                      onChange={(e) =>
                        this.setState({ tech_contact: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Technical Email</label>
                    <input
                      type="email"
                      required
                      name="technical_email"
                      defaultValue={this.state.tech_email}
                      className="form-control"
                      placeholder="Technical Email"
                      onChange={(e) =>
                        this.setState({ tech_email: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <label>Complete Address*</label>
                    <textarea
                      style={{ resize: "none" }}
                      className="form-control"
                      name="add"
                      row={5}
                      required
                      defaultValue={this.state.address}
                      onChange={(e) =>
                        this.setState({ address: e.target.value })
                      }
                    />
                  </div>

                  <div className="col-md-12">
                    <label>Other Addresses (One Address/Line)</label>
                    <textarea
                      style={{
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        minHeight: "110px",
                      }}
                      className="form-control"
                      name="other_add"
                      row={7}
                      required
                      defaultValue={this.state.other_address}
                      onChange={(e) =>
                        this.setState({ other_address: e.target.value })
                      }
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <br />
                      <label>Zip Code</label>
                      <input
                        className="form-control"
                        defaultValue={this.state.pin}
                        name="zip"
                        type="text"
                        required
                        onChange={(e) => this.setState({ pin: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 form-group">
                    <br />
                    <label>GSTIN</label>
                    <input
                      type="text"
                      required
                      name="gstin"
                      defaultValue={this.state.gstin}
                      className="form-control"
                      placeholder="Company GSTIN"
                      onChange={(e) => this.setState({ gstin: e.target.value })}
                    />
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      name="profile"
                      className="btn btn-primary wdt-bg"
                    >
                      Update Profile
                    </button>
                  </div>
                </div>
              </form>

              <br />
              <div className="box-header with-border">
                <h3 className="box-title">Change Logo</h3>
              </div>
              <br />

              {/*CHANGE LOGO*/}
              <form onSubmit={this.handleLogoFormSubmit.bind(this)}>
                <div className="row">
                  <div className="col-sm-2">
                    <img
                      src={images_root + this.state.logo}
                      alt="logo"
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div className="col-sm-10">
                    <div className="input-group col-md-5">
                      <input
                        type="file"
                        name="image_upload_file"
                        required
                        onChange={(e) =>
                          this.setState({ elogo: e.target.files[0] })
                        }
                      />
                    </div>
                    <br />
                    <button
                      type="submit"
                      name="changelogo"
                      className="btn btn-primary wdt-bg"
                    >
                      Update Logo
                    </button>
                  </div>
                </div>
              </form>
              <br />

              <div className="box-header with-border">
                <h3 className="box-title">Change Icon</h3>
              </div>
              <br />
              {/*Changing Icon*/}
              <form onSubmit={this.handleIconFormSubmit.bind(this)}>
                <div className="row">
                  <div className="col-sm-2">
                    <img
                      id="icon_img"
                      src={images_root + this.state.icon}
                      alt="icon"
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div className="col-sm-10">
                    <div className="input-group col-md-5">
                      <input
                        type="file"
                        id="icon_file"
                        required
                        onChange={(e) =>
                          this.setState({ eicon: e.target.files[0] })
                        }
                      />
                    </div>
                    <br />
                    <button
                      type="submit"
                      name="changeicon"
                      className="btn btn-primary wdt-bg"
                    >
                      Update Icon
                    </button>
                  </div>
                </div>
              </form>
              <br />
              <div className="box-header with-border">
                <h3 className="box-title">Change Password</h3>
              </div>
              <br />
              <form
                method="post"
                onSubmit={this.handlePasswordFormSubmit.bind(this)}
              >
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>Current password*</label>
                    <input
                      type="password"
                      required
                      name="current_pass"
                      className="form-control"
                      placeholder="Current Password"
                      onChange={(e) =>
                        this.setState({ current_pass: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>New Password*</label>
                    <input
                      type="password"
                      required
                      name="new_pass"
                      className="form-control"
                      placeholder="New Password"
                      onChange={(e) =>
                        this.setState({ new_pass: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Confirm New Password*</label>
                    <input
                      type="password"
                      required
                      name="confirm_pass"
                      className="form-control"
                      placeholder="Confirm Password"
                      onChange={(e) =>
                        this.setState({ confirm_pass: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      name="change"
                      className="btn btn-primary wdt-bg"
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div className="control-sidebar-bg" />
      </div>
    );
  }
}

class ReturnPolicy extends React.Component {
  constructor() {
    super();
    this.state = {
      max_day: "",
      charge_day: "",
      charging_type: "",
      charge_amount: "",
      policy_content: "",
    };

    let formData = new FormData();
    formData.append("return_policy", "policy");

    this.childKey = 0;

    axios({
      method: "post",
      url: api_link + "SetupFetch.php",
      data: formData,
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          max_day: data[0]["max_day"],
          charge_day: data[0]["charge_day"],
          charging_type: data[0]["type"],
          charge_amount: data[0]["amount"],
          policy_content: data[0]["policy_content"],
        });

        $("#charge_type").val(this.state.charging_type);
      });
  }

  componentDidMount() {
    document.title = "Return Policy Setup";
  }

  handleBasicFormSubmit(event) {
    event.preventDefault();
    $("#invalid_message").hide();

    let formData = new FormData();
    formData.append("return_policy_submit", "update");
    formData.append("max_days", this.state.max_day);
    formData.append("charge_days", this.state.charge_day);
    formData.append("type", this.refs.charge_type.value);
    formData.append("charge_amount", this.state.charge_amount);
    formData.append("dis", this.state.policy_content);

    axios({
      method: "post",
      url: api_link + "SetupManagement.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data === "ok") {
          $("#update_message").html(
            "<strong>Success! </strong> Your Request Successfully Processed"
          );
          $("#update_message")
            .removeClass("alert-danger")
            .addClass("alert-success");
        } else {
          $("#update_message").html(
            "<strong>Error! </strong> Unable to Process Your Request"
          );
          $("#update_message")
            .removeClass("alert-success")
            .addClass("alert-danger");
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        $("#update_message").show();
        setTimeout(function () {
          $("#update_message").hide();
        }, 4000);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section>
          <div
            id="update_message"
            className="alert"
            style={{ display: "none" }}
          ></div>
        </section>
        <section className="content-header">
          <h1> Return Policy Setup</h1>
        </section>
        <section className="content">
          <div className="box">
            <div className="box-body">
              {/*CHANGE BASIC DETAILS*/}
              <form
                method="post"
                onSubmit={this.handleBasicFormSubmit.bind(this)}
              >
                <div className="form-group">
                  <label>Maximum Days(To return a product)</label>
                  <input
                    type="number"
                    name="max_days"
                    className="form-control"
                    defaultValue={this.state.max_day}
                    id="max_days"
                    onChange={(e) => this.setState({ max_day: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Chargable Days(Day after which charges may apply)
                  </label>
                  <input
                    type="number"
                    name="charge_days"
                    className="form-control"
                    defaultValue={this.state.charge_day}
                    id="charge_days"
                    onChange={(e) =>
                      this.setState({ charge_day: e.target.value })
                    }
                  />
                </div>
                <div
                  id="cpa"
                  className="form-group col-md-6"
                  style={{ paddingLeft: 0 }}
                >
                  <label>Charging Amount Type</label>
                  <br />
                  <select
                    className="form-control"
                    name="type"
                    id="charge_type"
                    ref="charge_type"
                    defaultValue={this.state.charging_type}
                  >
                    <option value="p">As Percentage</option>
                    <option value="a">As Amount</option>
                  </select>
                </div>
                <div
                  id="ca"
                  className="form-group col-md-6"
                  style={{ paddingRight: 0 }}
                >
                  <label>Charging Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    name="charge_amount"
                    defaultValue={this.state.charge_amount}
                    id="charge_amount"
                    onChange={(e) =>
                      this.setState({ charge_amount: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Policy details</label>
                </div>
                <div className="col">
                  <div className="box box-info">
                    <CKEditor
                      config={{ versionCheck: false }}
                      content={this.state.policy_content}
                      required
                      activeClass="p10"
                      events={{
                        change: (e) =>
                          this.setState({ policy_content: e.editor.getData() }),
                      }}
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  className="btn btn-primary pull-right"
                  defaultValue="Submit"
                  name="submit"
                />
              </form>
            </div>
          </div>
        </section>
        <div className="control-sidebar-bg" />
      </div>
    );
  }
}

class AdminHome extends React.Component {
  render() {
    window.CKEDITOR_BASEPATH = "../node_modules/ckeditor/";

    return (
      <React.Fragment>
        <div
          id="loader-wrapper"
          className="loader_gif"
          style={{ display: "none" }}
        >
          <div id="loader">
            <img
              src={images_root + "main-loader.gif"}
              alt="loader"
              style={{ width: "100px" }}
            />
          </div>
        </div>
        <BrowserRouter>
          <Header />
          <aside className="main-sidebar">
            <Navbar />
          </aside>

          <Switch>
            <Route exact path="/products" component={Products} />
            <Route exact path="/product-tags" component={ProductSkuTags} />
            <Route exact path="/add_product" component={AddProduct} />
            <Route exact path="/edit_product/:token" component={EditProduct} />
            <Route
              exact
              path="/manage-catalogues"
              component={ManageCatalogues}
            />

            <Route exact path="/categories" component={HomeAndKitchen} />
            <Route exact path="/brand_stores" component={BrandStores} />
            <Route exact path="/categories-main" component={Categories} />

            <Route exact path="/active_customers" component={ActiveCustomers} />
            <Route exact path="/blocked_customers" component={BlockCustomers} />

            <Route exact path="/page_setup" component={DynamicPages} />
            <Route exact path="/slider_setup" component={SliderSetup} />
            <Route exact path="/social_links" component={SocialSetup} />
            <Route exact path="/faq" component={FAQs} />
            <Route exact path="/manage-stores" component={StoreSetup} />

            <Route exact path="/email" component={Email} />
            <Route
              exact
              path="/push_notifications"
              component={PushNotifications}
            />
            <Route exact path="/sms" component={SMS} />

            <Route exact path="/pending_queries" component={PendingQueries} />
            <Route
              exact
              path="/responded_queries"
              component={RespondedQueries}
            />

            <Route exact path="/profiles" component={Profile} />
            <Route exact path="/returnPolicy" component={ReturnPolicy} />

            <Route exact path="/*" component={Dashboard} />
            <Route exact path="/" component={Dashboard} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default AdminHome;
