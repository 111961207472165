import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import { api_link } from "../AdminLogin";

const ProductSkuTags = () => {
  const [skuTags, setSkuTags] = useState([]);
  const [updateMessage, setUpdateMessage] = useState({ text: "", type: "" });

  useEffect(() => {
    document.title = "Product SKU Tags";
    fetchSKUTags();
  }, []);

  const fetchSKUTags = () => {
    let skuTagsForm = new FormData();
    skuTagsForm.append("fetch_sku_tags", "all");
    skuTagsForm.append("multi", "yes");
    skuTagsForm.append("no_number", "yes");

    axios
      .post(`${api_link}ProductsFetch.php`, skuTagsForm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setSkuTags(response.data);

        $("script[src='js/dataTable.js']").remove();
        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
      })
      .catch((error) => console.error("Error fetching SKU Tags:", error));
  };

  const deleteSkuTag = (qid) => {
    if (window.confirm("Are You Sure To Delete This Tag?")) {
      let formData = new FormData();
      formData.append("delete_product_sku_tag", qid);

      axios
        .post(`${api_link}ProductSkuTagsManagement.php`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data === "ok") {
            setSkuTags((tags) => tags.filter((t) => t.value !== qid));
            setUpdateMessage({
              text: "Success! Your request was successfully processed.",
              type: "alert-success",
            });
          } else {
            setUpdateMessage({
              text: "Error! Unable to process your request.",
              type: "alert-danger",
            });
          }

          setTimeout(() => {
            setUpdateMessage({ text: "", type: "" });
          }, 4000);
        })
        .catch((error) => console.error("Error deleting product:", error));
    }
  };

  return (
    <div className="content-wrapper">
      <section>
        {updateMessage.text && (
          <div className={`alert ${updateMessage.type}`}>
            {updateMessage.text}
          </div>
        )}
      </section>

      <section className="content-header">
        <h1>Product SKU Tags</h1>
      </section>

      <section className="content">
        <div className="box">
          <div className="box-body table-responsive">
            <br />
            <table id="example1" className="table">
              <thead>
                <tr>
                  <th>Tag</th>
                  <th>Linked Products</th>
                  <th className="noExport">Action</th>
                </tr>
              </thead>
              <tbody>
                {skuTags.map((tag) => (
                  <tr key={tag.value}>
                    <td id={`name${tag.value}`}>{tag.label}</td>
                    <td>{tag.total}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        style={{ marginLeft: "5px" }}
                        onClick={() => deleteSkuTag(tag.value)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductSkuTags;
