import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import { api_link, images_root } from "../AdminLogin";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [updateMessage, setUpdateMessage] = useState({ text: "", type: "" });

  useEffect(() => {
    document.title = "Products";
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    let formData = new FormData();
    formData.append("products", "all");
    formData.append("sort_by", "home_and_kitchen");

    axios
      .post(`${api_link}ProductsFetch.php`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setProducts(response.data);
        $("script[src='js/dataTable.js']").remove();
        const script = document.createElement("script");
        script.src = "js/dataTable.js";
        script.async = true;
        document.body.appendChild(script);
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  const deleteProduct = (qid) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      let formData = new FormData();
      formData.append("delete_product", qid);

      axios
        .post(`${api_link}ProductsManagement.php`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data === "ok") {
            setProducts(products.filter((product) => product.id !== qid));
            setUpdateMessage({
              text: "Success! Your request was successfully processed.",
              type: "alert-success",
            });
          } else {
            setUpdateMessage({
              text: "Error! Unable to process your request.",
              type: "alert-danger",
            });
          }

          setTimeout(() => {
            setUpdateMessage({ text: "", type: "" });
          }, 4000);
        })
        .catch((error) => console.error("Error deleting product:", error));
    }
  };

  return (
    <div className="content-wrapper">
      <section>
        {updateMessage.text && (
          <div className={`alert ${updateMessage.type}`}>
            {updateMessage.text}
          </div>
        )}
      </section>

      <section className="content-header">
        <h1>Products</h1>
        <ol className="breadcrumb">
          <li>
            <div className="pull-right">
              <Link
                to="/add_product"
                className="btn btn-primary"
                style={{ marginRight: "15px" }}
              >
                <i className="fa fa-plus"></i>
              </Link>
            </div>
          </li>
        </ol>
      </section>

      <section className="content">
        <div className="box">
          <div className="box-body table-responsive">
            <br />
            <table id="example1" className="table">
              <thead>
                <tr>
                  <th className="noExport">Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th className="noExport">Action</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    <td style={{ maxWidth: "180px" }}>
                      <img
                        height="117px"
                        alt="p_image"
                        src={`${images_root}${product.product_image}`}
                      />
                    </td>
                    <td id={`name${product.id}`}>{product.name}</td>
                    <td>{product.price}</td>
                    <td>
                      {product.categories?.map((category, index) => (
                        <span key={index}>
                          {category}
                          <br />
                        </span>
                      ))}
                    </td>
                    <td>{product.status}</td>
                    <td style={{ minWidth: "170px" }}>
                      <Link
                        to={{
                          pathname: "/add_product",
                          state: { copy_id: product.id },
                        }}
                        className="btn btn-sm btn-info"
                        style={{ marginRight: "5px" }}
                      >
                        <i className="fa fa-copy"></i> Copy
                      </Link>
                      <Link
                        to={`edit_product/${product.id}`}
                        className="btn btn-sm btn-success"
                      >
                        <i className="fa fa-edit"></i> Edit
                      </Link>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        style={{ marginLeft: "5px" }}
                        onClick={() => deleteProduct(product.id)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
