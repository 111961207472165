import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CKEditor from "react-ckeditor-component";
import { api_link, editorConfig } from "../AdminLogin";
import { returnMultiSelectValues } from "../helpers";

const AddProduct = () => {
  const location = useLocation();
  const copyId = location.state?.copy_id || null;
  const [image, setImage] = useState("");
  const [productCode, setProductCode] = useState("");
  const [type, setType] = useState("Veg");
  const [name, setName] = useState("");
  const [pmodal, setPmodal] = useState("");
  const [price, setPrice] = useState(0);
  const [status, setStatus] = useState("Active");
  const [quantityType, setQuantityType] = useState("N/A");
  const [quantity, setQuantity] = useState(1);
  const [video_link, setVideo_link] = useState("");
  const [detailed_description, setDetailed_description] = useState("");
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [skuTags, setSkuTags] = useState([]);
  const [selectedSkuTags, setSelectedSkuTags] = useState(null);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [imageCount, setImageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch SKU Tags
  const fetchSKUTags = async () => {
    try {
      let formData = new FormData();
      formData.append("fetch_sku_tags", "all");
      formData.append("multi", "yes");
      formData.append("no_number", "yes");

      const response = await axios.post(
        `${api_link}ProductsFetch.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setSkuTags(response.data);
    } catch (error) {
      console.error("Error fetching SKU Tags:", error);
    }
  };

  // Fetch Brands
  const fetchBrands = async () => {
    try {
      let formData = new FormData();
      formData.append("type", "brands");

      const response = await axios.post(
        `${api_link}CategoriesFetch.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data.length) {
        setBrands(response.data);
        setSelectedBrand(response.data[0]["id"]);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // Fetch Categories
  const fetchCategories = async () => {
    try {
      let formData = new FormData();
      formData.append("type", "home");
      formData.append("multi", "yes");

      const response = await axios.post(
        `${api_link}CategoriesFetch.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    document.title = "Add Product";

    // Fetch all data in parallel
    const fetchData = async () => {
      await Promise.all([fetchSKUTags(), fetchBrands(), fetchCategories()]);
      setIsLoading(false); // Set loading to false once all data is fetched
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!isLoading && !!copyId) {
      $("#loader-wrapper").show();

      //FETCH DETAILS OF PRODUCT
      let c_product = new FormData();
      c_product.append("edit_product", copyId);
      axios({
        method: "post",
        url: api_link + "ProductsFetch.php",
        data: c_product,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((response) => response.data)
        .then((data) => {
          if (data.length) {
            setName(data[0].name);
            setPrice(data[0].price);
            setStatus(data[0].status);
            setVideo_link(data[0].video_link);
            setDetailed_description(data[0].detailed_description);
            setSelectedCategories(
              returnMultiSelectValues(
                categories,
                data[0]["home_and_kitchen_id"]
              )
            );
            setSelectedSkuTags(
              returnMultiSelectValues(skuTags, data[0]["sku_tags"])
            );
            setProductCode(data[0].product_code);
            setSelectedBrand(data[0].brand_id);
            setType(data[0].type);
            setQuantityType(data[0].quantity_type);
            setQuantity(data[0].quantity ?? 1);
            setPmodal(data[0].pmodal ?? "");
          }
        })
        .finally(() => {
          $("#loader-wrapper").fadeOut("slow");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, copyId]);

  const resetFields = () => {
    setImage("");
    setProductCode("");
    setType("");
    setName("");
    setPmodal("");
    setPrice(0);
    setStatus("Active");
    setQuantityType("N/A");
    setQuantity(1);
    setVideo_link("");
    setDetailed_description("");
    setSelectedCategories(null);
    setSelectedSkuTags(null);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    if (
      detailed_description &&
      detailed_description?.trim().length > 0 &&
      selectedCategories &&
      selectedCategories?.length > 0
    ) {
      $("#invalid_message").hide();
      $("#loader-wrapper").show();
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        let formData = new FormData();
        formData.append("add_product", "yes");
        formData.append("name", name);
        formData.append("productCode", productCode);
        formData.append("brand_id", selectedBrand);
        formData.append("pmodal", pmodal);
        formData.append("image", image);
        formData.append("type", type);
        formData.append("video_link", video_link);

        formData.append("price", price);
        formData.append("quantity", quantity);
        formData.append("quantity_type", quantityType);
        formData.append("status", status);
        formData.append("detailed_description", detailed_description);

        if (selectedCategories) {
          formData.append(
            "categories",
            selectedCategories?.map((item) => item.value).join() || ""
          );
        }
        if (selectedSkuTags) {
          var tempSelectedTags = [];
          selectedSkuTags.map((item) => {
            tempSelectedTags.push(item.value);
            return true;
          });
          formData.append("sku_tags", JSON.stringify(tempSelectedTags));
        }

        if ($("[name='b_images[]']").length) {
          $("[name='b_images[]']").each(function () {
            formData.append("additional_images[]", this.files[0]);
          });
        }

        axios({
          method: "post",
          url: api_link + "ProductsManagement.php",
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        })
          .then(function (response) {
            //handle success
            if (response.data === "ok") {
              document.getElementById("addProduct").reset();
              resetFields();
              fetchSKUTags();

              $("#update_msg").html(
                "<strong>Success! </strong> Your Request Successfully Processed"
              );
              $("#update_msg")
                .removeClass("alert-danger")
                .addClass("alert-success");
            } else {
              $("#update_msg").html(
                "<strong>Error! </strong> Unable to Process Your Request"
              );
              $("#update_msg")
                .removeClass("alert-success")
                .addClass("alert-danger");
            }
            $("#update_msg").show();
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(function () {
              $("#update_msg").hide();
            }, 4000);
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          })
          .finally(function () {
            // always executed
            $("#loader-wrapper").fadeOut("slow");
          });
      };
    }
  };

  const addImages = () => {
    var html = '<div class="col-md-12 row" id="added_image' + imageCount + '">';
    html += '<div class="col-md-8">';
    html +=
      '<input type="file" required class="form-control" name="b_images[]">';
    html += "</div>";
    html += '<div class="col-md-2">';
    html +=
      '<button type="button" onclick="$(\'#added_image' +
      imageCount +
      '\').remove();" data-toggle="tooltip" title="Remove" class="btn btn-danger bt-sm pull-right"><i class="fa fa-minus-circle"></i></button></td>';
    html += "</div>";
    html += "</div>";

    $("#banner_images").append(html);

    let tempImageCount = imageCount + 1;
    setImageCount(tempImageCount);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>Add Product</h1>
          <ol className="breadcrumb">
            <li>
              <div className="pull-right">
                <Link to="/products" className="btn btn-success">
                  <i className="fa fas fa-cart-plus"></i> Back to Products
                </Link>
              </div>
            </li>
          </ol>
        </section>
        <section>
          <div
            id="update_msg"
            className="alert"
            style={{ display: "none" }}
          ></div>
        </section>
        <section className="content" style={{ overflow: "auto" }}>
          <div className="box">
            <div className="box-body">
              <br />
              <form id="addProduct" onSubmit={(e) => handleAddFormSubmit(e)}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-xs-12 col-md-4">
                      <label>Product Code*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Example - YFH-151"
                        value={productCode}
                        onChange={(e) => setProductCode(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-xs-12 col-md-8">
                      <label>Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Product Name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-xs-12 col-md-3">
                      <br />
                      <label>Type*</label>
                      <select
                        className="form-control col-xs-12 col-md-6"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        required
                      >
                        <option>Veg</option>
                        <option>Non Veg</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-md-3 hide">
                      <br />
                      <label>Modal Type(if any)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="E.g. - Small, Big, Large"
                        value={pmodal}
                        onChange={(e) => setPmodal(e.target.value)}
                      />
                    </div>
                    <div className="col-xs-12 col-md-3">
                      <br />
                      <label>Brand*</label>
                      <select
                        className="form-control col-xs-12 col-md-6"
                        value={selectedBrand}
                        onChange={(e) => setSelectedBrand(e.target.value)}
                        required
                      >
                        {brands.length > 0 &&
                          brands.map((b) => (
                            <option value={b.id}>{b.name}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-xs-12 col-md-3">
                      <br />
                      <label>Price*</label>
                      <input
                        type="number"
                        step="0.01"
                        placeholder="Specifically for 1 KG/Litre/Unit"
                        className="form-control"
                        value={price}
                        required
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>

                    <div className="col-xs-12 col-md-3">
                      <br />
                      <label>Status*</label>
                      <select
                        className="form-control col-xs-12 col-md-6"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                      >
                        <option value="Active">Active</option>
                        <option value="Block">Block</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-md-3 hide">
                      <br />
                      <label>Quantity Type*</label>
                      <select
                        className="form-control col-xs-12 col-md-6"
                        value={quantityType}
                        onChange={(e) => setQuantityType(e.target.value)}
                      >
                        <option>N/A</option>
                        <option>Unit</option>
                        <option>Piece</option>
                        <option>Litre</option>
                        <option>Ml</option>
                        <option>Kg</option>
                        <option>Grams</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-md-3 hide">
                      <br />
                      <label>Quantity</label>
                      <input
                        type="number"
                        placeholder="In Unit/Grams/Ml"
                        className="form-control"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </div>
                    <div className="col-xs-12 col-md-12">
                      <br />
                      <label>Video Link</label>
                      <input
                        type="url"
                        className="form-control"
                        placeholder="E.g. - https://you.tube/xxxx"
                        value={video_link}
                        onChange={(e) => setVideo_link(e.target.value)}
                      />
                    </div>
                    <div className="col-xs-12 col-md-12">
                      <br />
                      <label>
                        SKU Tags
                        {(selectedSkuTags === null ||
                          selectedSkuTags?.length === 0) && (
                          <>
                            &nbsp;
                            <span style={{ color: "red" }}>
                              This field is required*
                            </span>
                          </>
                        )}
                      </label>
                      <CreatableSelect
                        options={skuTags}
                        isClearable={true}
                        value={selectedSkuTags}
                        id="skuTags"
                        name="skuTags"
                        required
                        isMulti={true}
                        onChange={(selectedSkuTags) =>
                          setSelectedSkuTags(selectedSkuTags)
                        }
                      />
                    </div>
                    <div className="col-xs-12 col-md-12">
                      <br />
                      <label>
                        Categories
                        {(selectedCategories === null ||
                          selectedCategories?.length === 0) && (
                          <>
                            &nbsp;
                            <span style={{ color: "red" }}>
                              This field is required*
                            </span>
                          </>
                        )}
                      </label>
                      <Select
                        options={categories}
                        value={selectedCategories}
                        id="categories"
                        name="categories"
                        required
                        onChange={(selectedCategories) =>
                          setSelectedCategories(selectedCategories)
                        }
                        isClearable={true}
                        isMulti={true}
                      />
                    </div>
                    <div className="col-xs-12 col-md-12">
                      <br />
                      <label>Image (Recommended - 1:1)</label>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginLeft: "20px" }}
                        onClick={() => addImages()}
                      >
                        <i className="fa fa-plus"></i>
                      </button>

                      <input
                        type="file"
                        className="form-control"
                        accept="image/*"
                        required
                        onChange={(e) => setImage(e.target.files[0])}
                      />

                      <div id="banner_images" className="row"></div>
                    </div>

                    <div className="col-xs-12 col-md-12">
                      <br />
                      <label>
                        Description
                        {(detailed_description === null ||
                          detailed_description?.length === 0) && (
                          <>
                            &nbsp;
                            <span style={{ color: "red" }}>
                              This field is required*
                            </span>
                          </>
                        )}
                      </label>
                      <CKEditor
                        config={editorConfig}
                        content={detailed_description}
                        activeClass="p10"
                        events={{
                          change: (e) =>
                            setDetailed_description(e.editor.getData()),
                        }}
                        required
                      />
                    </div>

                    <div className="col-xs-12 col-md-12">
                      <br />
                      <button
                        type="submit"
                        name="add"
                        className="btn pull-right btn-primary"
                      >
                        Add Data
                      </button>
                    </div>
                  </div>
                  <br />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default AddProduct;
