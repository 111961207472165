import React, { useState, useEffect } from "react";
import Select from "react-select";
import { api_link } from "../AdminLogin";
import axios from "axios";
import $ from "jquery";
import MUIDataTable from "mui-datatables";
import { numberField, returnMultiSelectValues } from "../helpers";

const ActiveCustomers = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [user_id, setUserId] = useState("");
  const [eid, setEid] = useState("");
  const [reason, setReason] = useState("");
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selected_products, setSelectedProducts] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [address, setAddress] = useState("");
  const [profileType, setProfileType] = useState("Territorial");

  const resetFormState = () => {
    setName("");
    setEmail("");
    setContact("");
    setState("");
    setCity("");
    setZipcode("");
    setLandmark("");
    setAddress("");
    setProfileType("Territorial");
    setSelectedProducts([]);
    setPassword("");
    setEid("");
  };

  const fetchCustomers = () => {
    setCustomers([]);
    let formData = new FormData();
    formData.append("active", "yes");

    axios({
      method: "post",
      url: api_link + "CustomersFetch.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then((response) => response.data)
      .then((data) => {
        setCustomers(data);
      });
  };

  useEffect(() => {
    document.title = "Active Franchises";

    const fetchProducts = () => {
      let formData = new FormData();
      formData.append("fetch_sku_tags", "all");
      formData.append("multi", "yes");

      axios({
        method: "post",
        url: api_link + "ProductsFetch.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((response) => response.data)
        .then((data) => {
          setProducts(data);
        });
    };

    fetchProducts();

    fetchCustomers();
  }, []);

  const blockUser = (userid) => {
    setUserId(userid);
    setModalVisible(true);
  };

  const handleBlockFormSubmit = (event) => {
    event.preventDefault();
    if (window.confirm("Do You Want To Block The User? Y/N")) {
      $("#update_message").hide();
      $(".loader_gif").show();
      let formData = new FormData();
      formData.append("block", user_id);
      formData.append("reason", reason);

      axios({
        method: "post",
        url: api_link + "CustomersManagement.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          $(".loader_gif").fadeOut("slow");
          if (response.data === "ok") {
            setModalVisible(false);
            const rows = customers.filter((c) => c.u_id !== user_id);
            setCustomers(rows);

            $("#update_message").html(
              "<strong>Success! </strong> Your Request Successfully Processed"
            );
            $("#update_message")
              .removeClass("alert-danger")
              .addClass("alert-success");
          } else {
            $("#update_message").html(
              "<strong>Error! </strong> Unable to Process Your Request"
            );
            $("#update_message")
              .removeClass("alert-success")
              .addClass("alert-danger");
          }
          $("#update_message").show();
          setTimeout(function () {
            $("#update_message").hide();
          }, 4000);
        })
        .catch(function (response) {
          //handle error
          $(".loader_gif").fadeOut("slow");
        });
    }
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    $("#invalid_message").hide();
    let formData = new FormData();
    formData.append("add_customer", "yes");
    formData.append("name", name);
    formData.append("profileType", profileType);
    formData.append("email", email);
    formData.append("contact", contact);
    formData.append("password", password);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("zipcode", zipcode);
    formData.append("landmark", landmark);
    formData.append("address", address);
    formData.append(
      "allowed_products",
      selected_products?.map((item) => item.value).join() || ""
    );

    axios({
      method: "post",
      url: api_link + "CustomersManagement.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        //                $(".loader_gif").hide();
        document.getElementById("addCustomer").reset();
        resetFormState();
        setAddCustomerModal(false);
        if (response.data === "ok") {
          fetchCustomers();

          $("#update_message").html(
            "<strong>Success! </strong> Your Request Successfully Processed"
          );
          $("#update_message")
            .removeClass("alert-danger")
            .addClass("alert-success");
        } else {
          $("#update_message").html(
            "<strong>Error! </strong> User Already Registered!"
          );
          $("#update_message")
            .removeClass("alert-success")
            .addClass("alert-danger");
        }
        $("#update_message").show();
        setTimeout(function () {
          $("#update_message").hide();
        }, 4000);
      })
      .catch(function (response) {
        //handle error
        $(".loader_gif").fadeOut("slow");
        console.log(response);
      });
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    $("#invalid_message").hide();

    let formData = new FormData();
    formData.append("edit_customer", eid);
    formData.append("name", name);
    formData.append("profileType", profileType);
    formData.append("email", email);
    formData.append("contact", contact);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("zipcode", zipcode);
    formData.append("landmark", landmark);
    formData.append("address", address);
    formData.append(
      "allowed_products",
      selected_products?.map((item) => item.value).join() || ""
    );

    axios({
      method: "post",
      url: api_link + "CustomersManagement.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        document.getElementById("editCustomer").reset();
        resetFormState();
        setEditCustomerModal(false);
        if (response.data === "ok") {
          fetchCustomers();

          $("#update_message").html(
            "<strong>Success! </strong> Your Request Successfully Processed"
          );
          $("#update_message")
            .removeClass("alert-danger")
            .addClass("alert-success");
        } else {
          $("#update_message").html(
            "<strong>Error! </strong> Unable to Process Your Request"
          );
          $("#update_message")
            .removeClass("alert-success")
            .addClass("alert-danger");
        }
        $("#update_message").show();
        setTimeout(function () {
          $("#update_message").hide();
        }, 4000);
      })
      .catch(function (response) {
        //handle error
        //			alert(JSON.stringify(response));
        $(".loader_gif").fadeOut("slow");
        console.log(response);
      });
  };

  const handlePassFormSubmit = (event) => {
    event.preventDefault();
    $("#invalid_message").hide();

    if ($("#n_pass").val() === $("#c_pass").val()) {
      if (window.confirm("Are you sure to change password?")) {
        let formData = new FormData();
        formData.append("update_customer_password", eid);
        formData.append("new_pass", $("#n_pass").val());

        axios({
          method: "post",
          url: api_link + "CustomersManagement.php",
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        })
          .then(function (response) {
            //handle success
            document.getElementById("editCustomer").reset();
            resetFormState();
            closeModal();
            if (response.data === "ok") {
              setEditModalVisible(false);

              $("#update_message").html(
                "<strong>Success! </strong> Your Request Successfully Processed"
              );
              $("#update_message")
                .removeClass("alert-danger")
                .addClass("alert-success");
            } else {
              $("#update_message").html(
                "<strong>Error! </strong> Unable to Process Your Request"
              );
              $("#update_message")
                .removeClass("alert-success")
                .addClass("alert-danger");
            }
            $("#update_message").show();
            setTimeout(function () {
              $("#update_message").hide();
            }, 4000);
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      }
    } else {
      alert("Please Enter Same Password!");
      $("#c_pass").focus();
    }
  };

  const handleTypeChange = (type) => {
    setProfileType(type);
    if (type === "Basic") {
      $(".productsList").show();
    } else {
      $(".productsList").hide();
      setSelectedProducts([]);
    }
  };

  const openEditModal = (customer, isCopyModal = false) => {
    setName(customer.name);
    setEmail(customer.email);
    setContact(customer.contact);
    setState(customer.state);
    setCity(customer.city);
    setZipcode(customer.zipcode);
    setLandmark(customer.landmark || "");
    setAddress(customer.address || "");
    handleTypeChange(customer.type);
    setSelectedProducts(
      returnMultiSelectValues(products, customer.allowed_products)
    );
    if (isCopyModal) {
      setAddCustomerModal(true);
    } else {
      setEid(customer.u_id);
      setEditCustomerModal(true);
    }
  };

  const closeModal = () => {
    setEditModalVisible(!editModalVisible);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section>
          <div
            id="update_message"
            className="alert"
            style={{ display: "none" }}
          ></div>
        </section>
        <section className="content-header">
          <h1>Active Franchises</h1>
          <ol className="breadcrumb">
            <li>
              <div className="pull-right">
                <button
                  title=""
                  className="btn btn-primary"
                  onClick={() => {
                    resetFormState();
                    handleTypeChange("Territorial");
                    setAddCustomerModal(true);
                  }}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </li>
          </ol>
        </section>

        <section className="content">
          <div className="box">
            <MUIDataTable
              data={customers?.map((customer, key) => {
                return [
                  customer.name,
                  customer.email,
                  customer.contact,
                  customer.type,
                  customer.state,
                  customer.city,
                  customer.zipcode,
                  <button
                    type="button"
                    name="copyCustomer"
                    className="btn btn-sm btn-info"
                    onClick={() => openEditModal(customer, true)}
                  >
                    <i className="fa fa-copy"></i>
                  </button>,
                  <button
                    type="submit"
                    name="block"
                    className="btn btn-sm btn-danger"
                    value="20"
                    onClick={() => blockUser(customer.u_id)}
                  >
                    <i className="fa fa-window-close btn-danger"></i>
                  </button>,
                  <button
                    type="button"
                    name="edit"
                    className="btn btn-sm btn-success"
                    value={customer.u_id}
                    onClick={() => {
                      setEid(customer.u_id);
                      setEditModalVisible(true);
                    }}
                  >
                    <i className="fa fa-key"></i>
                  </button>,
                  <button
                    type="button"
                    name="editCustomer"
                    className="btn btn-sm btn-success"
                    value={customer.u_id}
                    onClick={() => openEditModal(customer)}
                  >
                    <i className="fa fa-edit"></i>
                  </button>,
                ];
              })}
              columns={[
                "Name",
                "Email",
                "Contact",
                "Type",
                "State",
                "City",
                "Pincode",
                "Copy",
                "Block",
                "Pass",
                "Edit",
              ]}
              options={{ selectableRows: false }}
            />
          </div>
        </section>
      </div>

      <div
        className={"modal"}
        id="add_customer_modal"
        style={
          addCustomerModal
            ? { display: "block", overflow: "auto" }
            : { display: "none" }
        }
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => setAddCustomerModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Add Franchise</h4>
            </div>
            <form id="addCustomer" onSubmit={(e) => handleAddFormSubmit(e)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Type</label>
                    <select
                      className="form-control"
                      id="type"
                      name="type"
                      value={profileType}
                      onChange={(e) => handleTypeChange(e.target.value)}
                    >
                      <option>Territorial</option>
                      <option>Basic</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Contact</label>
                    {numberField(
                      "contact",
                      "contact",
                      contact,
                      setContact,
                      10,
                      10
                    )}
                  </div>

                  <div className="col-md-12">
                    <br />
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div
                    className="col-md-12 productsList"
                    style={{ display: "none" }}
                  >
                    <br />
                    <label>
                      SKU/Products Tags &nbsp;
                      <span
                        id="product_message"
                        style={{ color: "red", display: "none" }}
                      >
                        This field is required*
                      </span>
                    </label>
                    <Select
                      options={products}
                      value={selected_products}
                      isClearable={true}
                      id="products"
                      name="products"
                      onChange={(selected_products) =>
                        setSelectedProducts(selected_products)
                      }
                      isMulti={true}
                    />
                  </div>

                  <div className="col-md-6">
                    <br />
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      name="state"
                      value={state}
                      required
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6">
                    <br />
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      value={city}
                      required
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6">
                    <br />
                    <label>Zipcode</label>
                    <input
                      type="number"
                      className="form-control"
                      id="zipcode"
                      name="zipcode"
                      value={zipcode}
                      required
                      onChange={(e) => setZipcode(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6">
                    <br />
                    <label>Landmark</label>
                    <input
                      type="text"
                      className="form-control"
                      id="landmark"
                      name="landmark"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                  </div>

                  <div className="col-md-12">
                    <br />
                    <label>Address</label>
                    <textarea
                      className="form-control"
                      id="address"
                      name="address"
                      value={address}
                      style={{ resize: "none" }}
                      required
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default pull-left"
                  onClick={() => setAddCustomerModal(false)}
                >
                  Close
                </button>
                <button type="submit" name="add" className="btn btn-primary">
                  Add Data
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={"modal"}
        id="edit_customer_modal"
        style={
          editCustomerModal
            ? { display: "block", overflow: "auto" }
            : { display: "none" }
        }
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => setEditCustomerModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Edit Details</h4>
            </div>
            <form id="editCustomer" onSubmit={(e) => handleEditFormSubmit(e)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ename"
                      name="ename"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Type</label>
                    <select
                      className="form-control"
                      id="etype"
                      name="etype"
                      value={profileType}
                      onChange={(e) => handleTypeChange(e.target.value)}
                    >
                      <option>Territorial</option>
                      <option>Basic</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="eemail"
                      name="eemail"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <label>Contact</label>
                    {numberField(
                      "econtact",
                      "econtact",
                      contact,
                      setContact,
                      10,
                      10
                    )}
                  </div>

                  <div
                    className="col-md-12 productsList"
                    style={{ display: "none" }}
                  >
                    <br />
                    <label>SKU/Products Tags</label>
                    <Select
                      options={products}
                      value={selected_products}
                      isClearable={true}
                      id="products"
                      name="products"
                      required
                      onChange={(selected_products) =>
                        setSelectedProducts(selected_products)
                      }
                      isMulti={true}
                    />
                  </div>

                  <div className="col-md-6">
                    <br />
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      id="estate"
                      name="estate"
                      value={state}
                      required
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6">
                    <br />
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ecity"
                      name="ecity"
                      value={city}
                      required
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6">
                    <br />
                    <label>Zipcode</label>
                    <input
                      type="number"
                      className="form-control"
                      id="ezipcode"
                      name="ezipcode"
                      value={zipcode}
                      required
                      onChange={(e) => setZipcode(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6">
                    <br />
                    <label>Landmark</label>
                    <input
                      type="text"
                      className="form-control"
                      id="elandmark"
                      name="elandmark"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                  </div>

                  <div className="col-md-12">
                    <br />
                    <label>Address</label>
                    <textarea
                      className="form-control"
                      id="eaddress"
                      name="eaddress"
                      value={address}
                      style={{ resize: "none" }}
                      required
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default pull-left"
                  onClick={() => setEditCustomerModal(false)}
                >
                  Close
                </button>
                <button type="submit" name="edit" className="btn btn-primary">
                  Edit Data
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={"modal"}
        id="block_modal"
        style={
          modalVisible
            ? { display: "block", overflow: "auto" }
            : { display: "none" }
        }
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => setModalVisible(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Reason For Blocking</h4>
            </div>
            <form id="blockCustomer" onSubmit={(e) => handleBlockFormSubmit(e)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Reason</label>
                    <textarea
                      className="form-control"
                      id="reason"
                      name="reason"
                      style={{ resize: "none" }}
                      required
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default pull-left"
                  onClick={() => setModalVisible(false)}
                >
                  Close
                </button>
                <button type="submit" name="add" className="btn btn-primary">
                  Block User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={"modal"}
        id="password_modal"
        style={
          editModalVisible
            ? { display: "block", overflow: "auto" }
            : { display: "none" }
        }
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => setEditModalVisible(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Change Password</h4>
            </div>
            <form id="editCustomer" onSubmit={(e) => handlePassFormSubmit(e)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <label>New Password</label>
                    <input
                      type="text"
                      className="form-control"
                      id="n_pass"
                      name="n_pass"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Confirm Password</label>
                    <input
                      type="text"
                      className="form-control"
                      id="c_pass"
                      name="c_pass"
                      required
                    />
                  </div>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default pull-left"
                  onClick={() => setEditModalVisible(false)}
                >
                  Close
                </button>
                <button type="submit" name="edit" className="btn btn-primary">
                  Change Pass
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActiveCustomers;
